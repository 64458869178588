.cards-bloc {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    & .deck {
        cursor: pointer;
        width: 310px;
        height: 210px;
        position: relative;
        & .back-card {
            width: 300px;
            height: 200px;
            background-image: url('../assets/card-verso.png');
            background-size: cover;
            background-position: center;
            position: relative;
            border-radius:  30px;
            border: 1px solid rgb(172, 172, 172);
            text-align: center;
            & p {
                color: white;
                font-family: 'payback', sans-serif;
                font-size: 1.4rem;
                letter-spacing: .2rem;
                margin-top: 1rem;
            }
        }
        :nth-child(1) {
            z-index: 11;
        }
        :nth-child(2) {
            top: -210px;
            right: -5px;
            z-index: 10;
        }
        :nth-child(3) {
            top: -420px;
            right: -10px;
            z-index: 9;
        }
        :nth-child(4) {
            top: -630px;
            right: -15px;
            z-index: 8;
        }
        :nth-child(5) {
            top: -840px;
            right: -20px;
            z-index: 7;
        }
    }
}
.dialog-card {
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: rgba($color: black, $alpha: .6);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .card {
        margin-top: 10rem;
        width: 350px;
        height: 250px;
        border-radius:  30px;
        box-shadow: 1px -1px 2px white;
        overflow: hidden;
        .showCard {
            background-image: url('../assets/card-recto.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            margin: auto;
            & div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                & h1 {
                font-family: 'payback', sans-serif;
                color:  white;
                font-size: 2rem;
                letter-spacing: .5rem;
                margin: 1rem;
                }
                & p {
                    color:  white;
                    font-size: 1.1rem;
                    width: 85%;
                    margin: 0;
                }
            }
        }
    }
    .button-list-teams {
        display: flex;
        gap: 1rem;
        max-width: 320px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -10px;
        button {
            padding: .5rem 1rem;
            background-color: green;
        }
    }
}

.openCard {
    display: inline-block;
}

.closeCard {
    display: none;
}

.closeButton {
    margin-right: 1rem;
    margin-top: 0.5rem;
    align-self: flex-end;
    color: white;
    rotate: 45deg;
    font-size: 2.2rem;
    cursor: pointer;
}