.custom-button {
    font-family: 'payback', sans-serif;
    color: white;
    letter-spacing: .3rem;
    background-color: $colorPrimary;
    padding: 1.2rem 1.2rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}