.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        & div {
            display: flex;
            gap: 1rem;
            font-family: 'payback', sans-serif;
            letter-spacing: .15rem;
            align-items: center;
            input {
                width: 8rem;
                height: 1.5rem;
                border-radius: 20px;
                text-align: center;
            }
        }
    }
}

.add-field {
    background-color: green;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .8rem;
}

.remove-field {
    background-color: rgb(114, 2, 2);
    font-size: 35px;
    border-radius: 50%;
    font-weight: bold;
    width: 40px;
    rotate: 45deg;
    padding: 0;
}