.wireframe {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    gap: 1.2rem;
    & div {
        top: 0;
        position: fixed;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: black, $alpha: .6);
        z-index: 1000;
        .closeButton {
            margin-top: 2rem;
            margin-right: 2rem;
        }
        & img {
            width: 350px;
            height: 500px;
            object-fit: contain;
        }
    }
    & .wireframe-button {
        background-color: orange;
    }
}